import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useOrganization } from "context/OrganizationContext";
import { OrgInfoForm } from "components/Organizations/OrgInfoForm";
import { createOrganization } from "services/organizations-service";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ScrollView } from "react-native-web";
import { useLoading } from "context/LoadingContext";
import { cloneDeep } from "lodash";

const defaultDonationAmounts = [
  {
    amount: 25,
    default: false,

  },
  {
    amount: 50,
    default: false,

  },
  {
    amount: 100,
    default: true,

  },
  {
    amount: 250,
    default: false,

  }
]

export default function CorporateAccount() {
  const { currentUser, setOnboarding } = useAuth();
  const { newOrg } = useOrganization();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [customDonationAmounts, setCustomDonationAmounts] = useState(cloneDeep(defaultDonationAmounts));

  const [details, setDetails] = useState({ ...newOrg });
  const [useMailingAddress, setUseMailingAddress] = useState(false);
  const [selectedCauses, setSelectedCauses] = useState([]);

  const [phoneErr, setPhoneErr] = useState(false);
  const [adminEmailErr, setAdminEmailErr] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [newOrgCoverPhoto, setNewOrgCoverPhoto] = useState(
    newOrg?.coverPhoto ? [newOrg?.coverPhoto] : []
  );

  const [newOrgProfilePic, setNewOrgProfilePic] = useState(
    newOrg?.profilePic ? [newOrg?.profilePic] : []
  );

  let phoneRegEx =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  //setting disabled
  useEffect(() => {
    // console.log('add', details?.address)
    if ((details?.address?.city && !details?.address?.line1) || (!details?.address?.city && details?.address?.zipCode)) {
      // console.log('here')
      setUseMailingAddress(true);
    }

    if (details?.type === "business") {
      setDisabled(
        !details?.name ||
          !details?.about ||
          !details?.address?.line1 ||
          !details?.address?.city ||
          !details?.address?.state ||
          !details?.address?.zipCode ||
          newOrgCoverPhoto.filter((x) => x !== undefined).length === 0
      );
    } else {
      setDisabled(
        !details?.name ||
          !details?.about ||
          !details?.address?.line1 ||
          !details?.address?.city ||
          !details?.address?.state ||
          !details?.address?.zipCode ||
          !details?.phone ||
          !details?.adminEmail ||
          phoneErr ||
          adminEmailErr ||
          details.ein?.length !== 10 ||
          !selectedCauses?.length ||
          newOrgCoverPhoto.filter((x) => x !== undefined).length === 0
      );
    }
  }, [
    details,
    phoneErr,
    adminEmailErr,
    newOrgCoverPhoto,
    newOrgProfilePic,
    selectedCauses,
  ]);

  useEffect(() => {
    if (
      details?.address?.line1 === undefined ||
      details?.address?.zipCode === undefined
    ) {
      setDetails({
        ...details,
        address: {
          line1:
            details?.address?.line1 === undefined
              ? null
              : details?.address?.line1,
          city: details?.address?.city,
          zipCode:
            details?.address?.zipCode === undefined
              ? null
              : details?.address?.zipCode,
          state: details?.address?.state,
        },
      });
    }

    phoneChecker();
    emailChecker();
  }, [details]);

  const phoneChecker = () => {
    if (details.phone !== "") {
      if (phoneRegEx.test(details.phone)) {
        setPhoneErr(false);
      } else {
        setPhoneErr("Phone Number Invalid");
      }
    }
  };

  const emailChecker = () => {
    if (details.adminEmail !== "") {
      if (emailRegEx.test(details.adminEmail)) {
        setAdminEmailErr(false);
      } else {
        setAdminEmailErr("Email Invalid");
      }
    }
  };

  function createPressed() {
    setLoading(true);
    details.causes = selectedCauses || [];
    details.useMailingAddress = useMailingAddress;
    createOrganization(
      currentUser,
      details,
      newOrgCoverPhoto,
      newOrgProfilePic ? newOrgProfilePic : [],
      (doc) => {
        // setOnboarding(false);
        setLoading(false);
        if (newOrg?.type === "business") {
          navigate(Links.AccountCreatedStep2, { state: doc });
        } else {
          navigate(Links.VerifyNonProfit, { state: doc });
        }
      }
    );
  }

  return (
    <ScrollView style={{ width: "100%", maxWidth: 1100 }}>
      <View style={styles.container}>
        <img
          src={require("assets/rayze.png")}
          width={77.64}
          style={{ marginBottom: 30 }}
        />

        <PrimaryText fontSize={54} fontWeight="900">
          {newOrg?.type === "nonprofit" ? "Nonprofit" : "Corporate"} Account
        </PrimaryText>

        <PrimaryText
          fontSize={32}
          style={{ color: COLORS.primary.gray, marginTop: 14 }}
        >
          Enter Your {newOrg?.type === "nonprofit" ? "Nonprofit" : "Corporate"}{" "}
          Information
        </PrimaryText>

        <View style={{ marginTop: 28, width: "80%" }}>
          <OrgInfoForm
            details={details}
            setDetails={setDetails}
            coverPhotos={newOrgCoverPhoto}
            setCoverPhotos={setNewOrgCoverPhoto}
            profilePic={newOrgProfilePic}
            setProfilePic={setNewOrgProfilePic}
            selectedCauses={selectedCauses}
            setSelectedCauses={setSelectedCauses}
            phoneErr={phoneErr}
            adminEmailErr={adminEmailErr}
            useMailingAddress={useMailingAddress}
            setUseMailingAddress={setUseMailingAddress}
            business={details?.type === "business"}
            customDonationAmounts={customDonationAmounts}
            setCustomDonationAmounts={setCustomDonationAmounts}
          />
          <View style={styles.row}>
            {/* <View style={{ flexGrow: 1 }}>
            <CustomInput
              style={{ marginBottom: 20 }}
              title="Organization Name"
              placeholder="Enter Organization Name"
              mandatory={true}
            />
            <CustomInput
              style={{ marginBottom: 4 }}
              title="Address (will be displayed on map)"
              placeholder="Enter Address"
              mandatory={true}
            />
            <PrimaryText
              fontSize={17}
              style={{ color: COLORS.primary.gray, marginBottom: 20, marginLeft: 20 }}
            >*This address cannot be a PO Box</PrimaryText>

            <CustomInput
              style={{ marginBottom: 20 }}
              title="Employee Identification Number (EIN)"
              placeholder="Enter EIN"
              mandatory={true}
            />
            <CustomInput
              style={{ marginBottom: 20 }}
              title="About"
              placeholder="Describe your organization (500 characters max)"
              mandatory={true}
              multiline={true}
            />
            
          </View>

          <View style={{ width: 80 }} />

          <View style={{flex: 1}}>
          <CustomInput
              style={{ marginBottom: 20 }}
              title="Website URL"
              placeholder="Enter URL"
            />
            <UploadPhoto
              title="Upload Cover Photo"
              mandatory
              style={{ marginBottom: 50 }}
            />
            <UploadPhoto title="Upload Profile Photo" />
          </View> */}
          </View>
        </View>

        <PrimaryButton
          title="Create"
          style={{ marginTop: 20 }}
          // to={Links.Congrats}
          disabled={disabled}
          onPress={createPressed}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
